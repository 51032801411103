import React from 'react';
import Summary from './Summary';

import styles from './index.module.scss';

const Index = ({ loading }: { loading: boolean }) => {
  return (
    <div className={styles.skuDetailv2_m}>
      <Summary loading={loading} />
    </div>
  );
};

export default Index;
