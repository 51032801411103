/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-17 19:38:19
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2025-03-21 20:00:40
 * @FilePath: /isp_International/src/pages/MaterialCode/components/SearchForm/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
interface SearchFormProps {
  getDataList: any;
}
const SearchForm: React.FC<SearchFormProps> = props => {
  const { getDataList } = props;
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const formItem1 = (
    <Form.Item key="formItem8" name="materialId" label={TLT(`物料编码`)}>
      <Input
        data-track={JSON.stringify({
          eventId: 'MaterialManagement_MaterialCodeInput',
        })}
        placeholder={TLT(`请输入`) + TLT(`物料编码`)}
      />
    </Form.Item>
  );
  const formItem2 = (
    <Form.Item key="formItem8" name="mkuId" label={TLT(`京东商品编码`)}>
      <Input
        data-track={JSON.stringify({
          eventId: 'MaterialManagement_MKUInput',
        })}
        placeholder={TLT(`请输入`) + TLT(`京东商品编码`)}
      />
    </Form.Item>
  );

  const formItems = [formItem1, formItem2];

  const onFinish = async (values: any) => {
    getDataList && getDataList(values);
  };

  return (
    <div className={styles.searchForm}>
      <Form form={form} layout='inline' colon={false} name="control-hooks" onFinish={onFinish} className={styles.searchForm}>
        {formItems}
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            // clstag={isAudit ? 'h|keycount|ordercenter|4' : 'h|keycount|myorder|9'}
            data-track={JSON.stringify({
              eventId: 'MaterialManagement_Search',
            })}
            type="primary"
            htmlType="submit"
            style={{ marginRight: '20px' }}
            onClick={() => {
              log('jdisp', 'OrderList_Search');
            }}
          >
            {TLT(`查询`)}
          </Button>

          <Button
            // clstag={isAudit ? 'h|keycount|ordercenter|3' : 'h|keycount|myorder|8'}
            data-track={JSON.stringify({
              eventId: 'MaterialManagement_Reset',
            })}
            type="primary"
            ghost
            onClick={() => {
              form.resetFields();
              getDataList && getDataList({
                index: 1,
                size: 20,
              })
              //   setSearchOrderTimeType(1);
              //   setSearchOrderTime([]);
              //   setSelectedBuyAccounts([]);
              //   onReset();
            }}
          >
            {TLT(`重置`)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SearchForm;
