import { getUserConfig } from '@/api/newApi/user'
import React, { createContext, useState, useContext, useEffect } from 'react';

interface UserPermissions {
  [key: string]: boolean;
}

interface PermissionsContextType {
  permissions: UserPermissions;
  loading: boolean;
  error: Error | null;
  checkPermission: (key: string) => boolean;
}

export let checkPermission: (key: string) => boolean;

const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

export const PermissionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [permissions, setPermissions] = useState<UserPermissions>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        setLoading(true);
        // 假设 API 端点为 '/api/user-settings'
        const response = await getUserConfig();
        if (response.success) {
          setPermissions(response.value);
        }
        console.log('loading fetch', false)
        setLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred'));
        setLoading(false);
      }
    };

    fetchUserSettings();
  }, []);

  checkPermission = (key: string): boolean => {
    return permissions[key];
  };

  return (
    <PermissionsContext.Provider value={{ permissions, loading, error, checkPermission }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
};

