import React, { useEffect, useState } from 'react';

import { RecoilRoot } from 'recoil';
import { useParams } from 'react-router-dom';
import cookies from 'react-cookies';

import { Spin } from 'antd';

// 新的API
import {
  breadCrumbDataFormat,
  getProDetail,
  getProGroup,
} from '@/api/newApi/skudetail';

import Graphic from './components/Graphic';
import Main from './components/Main';
import Nav from './components/Nav';
import Parameter from './components/Parameter';

import { useSetRecoilState, useRecoilValue } from './hooks';

import styles from './index.module.scss';
import { querySpecialAttr } from '@/api/newApi/home';
import { moveToFirst } from '@/util/tools';

const Index = () => {
  const { skuId } = useParams<Record<string, any>>();
  const [stationtype] = useState(cookies.load('stationType'));
  const [loading, setloading] = useState(false);

  const setBigField = useSetRecoilState('bigField');
  const setBasicInfo = useSetRecoilState('basicInfo');
  const setProductAttr = useSetRecoilState('productAttr');
  const setSimilarProduct = useSetRecoilState('similarProduct');
  const setProductCategory = useSetRecoilState('productCategory');
  const setMaterialInfo = useSetRecoilState('materialInfo');
  const specialAttrs = useSetRecoilState('specialAttrs');

  // const amount = useRecoilValue('amount');

  useEffect(() => {
    setloading(true);
    getProDetail({
      mkuId: +skuId,
    })
      .then((res: any) => {
        if (res?.success) {
          const value = res.value;
          setBigField(value.bigField);
          setBasicInfo(value.baseInfo);
          setProductAttr(value.productAttr);
          setProductCategory(breadCrumbDataFormat(value.productCategory));
          console.log(value?.materialInfo, 'value?.materialInfo');
          setMaterialInfo(value?.mkuMaterial);
        }
      })
      .finally(() => {
        setloading(false);
      });

    getProGroup({
      mkuId: +skuId,
    }).then((res: any) => {
      if (res?.success) {
        setSimilarProduct(res.value);
      }
    });
   
    //京准达
    querySpecialAttr({
      mkuIds:[+skuId]
    }).then((res) => {
      if(res?.success){
        let result = Object.entries(res?.value[Object.keys(res?.value)[0]]).map(([key, value]) => ({
          key,
          value
        }));
        //跨境放第一位
        const index = result.findIndex((ele)=>ele.key == 'CrossBorder')
        result = moveToFirst(result, index)
        specialAttrs(result)
      }
    })
  }, [stationtype]);

  return (
    <Spin spinning={loading}>
      <div className={styles.m}>
        <Main loading={loading} />
        {/*<Models />*/}
        <div className={styles.mc}>
          <Nav />
          <Parameter />
          <Graphic />
        </div>
      </div>
    </Spin>
  );
};

const Container: React.FC = () => {
  return (
    <RecoilRoot>
      <Index />
    </RecoilRoot>
  );
};

export default Container;
