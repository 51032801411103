import React from 'react';
import styles from './index.module.scss';

interface ProductInfoComponentProps {
  type: number;
  symbol: string;
  salePrice: string;
  price: string;
}

const styleMap: Record<number, string> = {
  1: styles.type1,
  2: styles.type2,
  3: styles.type3,
  4: styles.type4,
};

const ProductInfoComponent: React.FC<ProductInfoComponentProps> = ({ type, symbol, salePrice, price }) => {
  return (
    <div className={`${styles.desContainer} ${type && styleMap[type]}`}>
      {!salePrice && !price ? (
        <div className={styles.contain_price}>
          <span className={styles.unknownPrice}>{TLT('未知价格')}</span>
        </div>
      ) : (
        <>
          {salePrice && (
            <div className={styles.contain_price}>
              <div className={styles.price_wrap}>
                <span className={styles.dollar}>{symbol}</span>
                <span className={styles.price}>{salePrice}</span>
              </div>
              <span className={styles.label}>{TLT(`含税价`)}</span>
            </div>
          )}

          {price && (
            <div className={styles.contain_price}>
              <div className={styles.price_wrap}>
                <span className={styles.dollar}>{symbol}</span>
                <span className={styles.price}>{price}</span>
              </div>
              <span className={styles.label}>{TLT(`未税价`)}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductInfoComponent;
