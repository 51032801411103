import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import cookies from 'react-cookies';
import Descriptions from './Descriptions';
import LocalSimilarPro from './LocalSimilarPro';
import DSpecifications from './DetailedSpecifications';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import Carousel from './Carousel';
import Panel from './Panel';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { formatMoney, getPrice } from '@/util';
import { toPercent } from '@/util/tools';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import iconCopy from '@/assets/images/skuDetail/copy.png';
import { Tooltip } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Des from '@/components/Atomics/Des/des';
import Price from '@/components/Atomics/Prices/price';
import { isNumber } from 'lodash';
const inventoryNumberClassName = styles.inventoryNumber;
const Index = ({ loading }: { loading: boolean }) => {
  const [stationType, setStationtype] = useState(cookies.load('stationType')); // 跨境还是本土站点
  // const main = useRecoilValue('main');
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const basicInfo = useRecoilValue('basicInfo');
  const materialInfo = useRecoilValue('materialInfo');

  // 获取用户操作权限
  const userLimit: any = useSelector((state: any) => {
    return state?.UserLimit;
  });
  // const { t } = useTranslation();
  // const item =
  //   main?.propertyList &&
  //   main?.propertyList.length > 0 &&
  //   main?.propertyList[0].key === '订货号' &&
  //   main?.propertyList[0];

  // 是否显示型号 （具体逻辑看readme）
  // const Model = () => {
  //   if (!item) {
  //     const _m = main?.basicInfo && main?.basicInfo?.model;
  //
  //     if (_m && main?.pageType !== 1) {
  //       return (
  //         <div className={styles.tag_brand}>
  //           <div className={styles.tag_brand_title}>型号</div>
  //           <div className={styles.tag_brand_name}>{_m}</div>
  //         </div>
  //       );
  //     } else {
  //       return <div></div>;
  //     }
  //   }
  // };

  const searchBrand = () => {
    // if (main?.basicInfo?.brandName) {
    //   window._openPage(
    //     `/rede/page/${main?.pageType === 1 ? 'list' : 'listCable'}?key=` +
    //       encodeURIComponent(main?.basicInfo?.brandName),
    //     '_self',
    //   );
    // }

    if (basicInfo?.brandInfoVo?.name) {
      // window._openPage(
      //   `/rede/page/${basicInfo?.isMainBrand === 1 ? 'list' : 'listCable'}?key=` +
      //     encodeURIComponent(basicInfo?.brandInfoVo?.name),
      //   '_self',
      // );
      const path = {
        pathname: '/page/list',
        query: basicInfo?.brandInfoVo?.name,
      };
      history.push(path);
    }
    //
  };

  const renderInventoryStatus = () => {
    if (basicInfo?.stockFlag === 4) {
      return <div className={styles.inventoryText}>{TLT('采购中')}</div>;
    }
    if (basicInfo?.remainNum === 0) {
      return <div className={styles.inventoryText}>{TLT('无货')}</div>;
    }
    if (basicInfo?.remainNum > 0 && basicInfo?.remainNum <= 200) {
      return (
        <div
          className={styles.inventoryText}
          dangerouslySetInnerHTML={{
            __html: TLT('仅剩XX件').replace(
              'XX',
              `<span style="color: rgb(225, 37, 27)">${basicInfo?.remainNum}</span>`
            ),
          }}
        ></div>
      );
    }
    return null;
  };

  return (
    <div className={styles.m}>
      {/*<div className={styles.pic}>{main?.images && <Carousel images={main?.images} />}</div>*/}

      {/**/}
      <div className={styles.pic}>{basicInfo?.imageDTOList && <Carousel images={basicInfo?.imageDTOList} />}</div>
      {/**/}

      <div className={styles.info}>
        <div className={styles.info_box}>
          {/* 品牌 */}
          {basicInfo?.brandInfoVo?.name ||
            (basicInfo?.brandInfoVo?.logoUrl && (
              <div className={styles.tag_brand_img} onClick={searchBrand}>
                <div className={styles.tag_brand_wrap}>
                  {/* <span className={styles.tag_brand_section}></span> */}
                  {TLT('品牌')}
                </div>
                <div className={styles.tag_brand_value}>
                  {basicInfo?.brandInfoVo?.logoUrl ? (
                    <img
                      className={styles.tag_brand_logo}
                      src={
                        basicInfo?.brandInfoVo?.logoUrl?.match('http')
                          ? basicInfo?.brandInfoVo?.logoUrl
                          : `https://img30.360buyimg.com/popshop/${basicInfo?.brandInfoVo?.logoUrl}`
                      }
                    />
                  ) : (
                    <span className={styles.tag_brand_name}>{basicInfo?.brandInfoVo?.name}</span>
                  )}
                </div>
              </div>
            ))}

          <span className={styles.product_name}>{basicInfo?.productName}</span>

          {/**/}
        </div>

        {/* 库存 */}
        <div
          className={`${styles.inventoryModule} ${
            basicInfo?.remainNum === 0 || (basicInfo?.remainNum > 0 && basicInfo?.remainNum <= 200) || basicInfo?.stockFlag === 4
              ? styles.inventoryModuleMargin
              : styles.inventoryModuleHide
          }`}
        >
          <ExclamationCircleFilled style={{ color: '#faad14' }} />
          <div className={styles.inventoryInfo}>
            {renderInventoryStatus()}
          </div>
        </div>

        {/* 价格 */}
        <div className={styles.priceContainer}>
          <div className={styles.priceSection}>

            {
              !loading && !isNumber(basicInfo?.showCurrency?.salePrice) && !isNumber(basicInfo?.showCurrency?.price) ?
              <div className={styles.priceRow}>
                <div className={styles.priceWrapper}>
                  <span className={styles.currencySymbol}>{basicInfo?.showCurrency?.symbol}</span>
                  <span className={styles.priceAmount}>{TLT('未知价格')}</span>
                </div>
              </div> : ''
            }

            {
              isNumber(basicInfo?.showCurrency?.salePrice) ?
              <div className={styles.priceRow}>
                <div className={styles.priceWrapper}>
                  <span className={styles.currencySymbol}>{basicInfo?.showCurrency?.symbol}</span>
                  <span className={styles.priceAmount}>
                    {basicInfo?.showCurrency?.salePrice &&
                      (basicInfo?.skuSaleState?.saleState == 0
                        ? TLT(`入池后可查`)
                        : basicInfo?.showCurrency?.salePrice ? formatMoney(basicInfo?.showCurrency?.salePrice, false) : TLT('未知价格'))}
                  </span>
                </div>
                <span className={styles.priceLabel}>{TLT(`含税价`)}</span>
              </div> : ''
            }

            {
              isNumber(basicInfo?.showCurrency?.price) ?
              <div className={styles.priceRow}>
                <div className={styles.priceWrapper}>
                  <span className={styles.currencySymbol}>{basicInfo?.showCurrency?.symbol}</span>
                <span className={styles.priceAmount}>
                  {basicInfo?.showCurrency?.price && (
                    <>
                      {basicInfo?.skuSaleState?.saleState == 0
                        ? TLT(`入池后可查`)
                        : getPrice(basicInfo?.showCurrency?.price).float ?
                          `${formatMoney(getPrice(basicInfo?.showCurrency?.price).int, false)}.${
                            getPrice(basicInfo?.showCurrency?.price).float
                          }` : TLT('未知价格')}
                    </>
                  )}
                </span>
              </div>
                <span className={styles.priceLabel}>{TLT(`未税价`)}</span>
              </div> : ''
            }

          </div>
          <div className={styles.taxInfoSection}>
            <div className={styles.taxRow}>
              <Des
                autoSize
                label={TLT(`税率`)}
                copy={false}
                text={String(toPercent(basicInfo?.showCurrency?.valueAddedTaxRate || 0))}
              ></Des>
            </div>

            <Des autoSize label={TLT(`商品编码`)} text={String(basicInfo?.skuId || '-')} preSplitLine={true}></Des>

            {userLimit?.limitData?.enableMaterial == 1 && (
              <Des
                autoSize
                label={TLT(`物料编码`)}
                text={String(materialInfo?.materialId || '-')}
                preSplitLine={true}
              ></Des>
            )}
          </div>
        </div>

        <div className={styles.descriptions}>
          <LocalSimilarPro />
          <Panel></Panel>
        </div>
      </div>
    </div>
  );
};

export default Index;
