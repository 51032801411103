import React from 'react';
import styles from './index.module.scss';
import { getJdiOSFile } from '@/util/index';
import { ReactComponent as IconPrint } from '@/assets/images/orderList/icon_print1.svg';
import { useSelector } from 'react-redux';

interface ProductTagProps {
  type: 'cross' | 'print' | '48h';
  align?: 'start' | 'end';
  onClick?: () => void;
}

const ProductTag: React.FC<ProductTagProps> = ({ type, onClick, align = 'start' }) => {
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });

  return (
    <div className={styles.tags} style={{ justifyContent: align }}>
      {type === 'cross' && (
        <div className={`${styles.crossTag} ${styles.tagItem}`}>
          <img className={styles.crossIcon} src={getJdiOSFile('mubla1/195354135364234883.png')} alt="cross Tag" />
          <span className={styles.crossSection}>{TLT('跨境')}</span>
        </div>
      )}
      {type === 'print' && currentUser.userType !== 6 && (
        <div className={`${styles.prientTag} underText`} onClick={onClick}>
          <IconPrint
            style={{
              width: '12px',
              height: '12px',
              color: 'rgb(153, 153, 153)',
              fill: 'currentcolor',
            }}
            className={styles.prientIcon}
          />
          {TLT('订单打印')}
        </div>
      )}
      {
        type === '48h' && (
          <div className={styles.hour48Tag}>
            <img className={styles.hour48Tag_icon} src={getJdiOSFile('mubla1/204101310223891078.png')} />
            <span className={styles.hour48Tag_section}>{TLT('48小时达')}</span>
          </div>
        )
      }
    </div>
  );
};

export default ProductTag;
