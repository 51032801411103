/*
 * @Author: duchongchong1
 * @Date: 2022-11-01 21:42:34
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2025-01-13 11:45:30
 * @FilePath: /isp_International/src/layout/BaseLayout.tsx
 * @Description:
 */
import React, { useEffect, useCallback } from 'react';
import { ConfigProvider, message } from 'antd';
import { useSelector } from 'react-redux';
import { antDthemes, defaultTheme, loadGlobalVar } from './themes/themes';
import { ThemeTypes } from '@/layout/themes/themeManage';
import { PermissionsProvider } from '@/util/hooks/checkPermissions';
import { toReport } from '@/dataPort/handler'

// -- multiple langs TODO --
import zh from 'antd/lib/locale/zh_CN';
import ru from 'antd/lib/locale/ru_RU';
import vi from 'antd/lib/locale/vi_VN';
import en from 'antd/lib/locale/en_US';
import hu from 'antd/lib/locale/hu_HU';
import th from 'antd/lib/locale/th_TH';
import ms from 'antd/lib/locale/ms_MY';
import pt_BR from 'antd/lib/locale/pt_BR';

import NewFooter from '@/components/NewFooter';
import Menu from '@/components/NavBar';
import { getLocal, LocalNameEnum } from '@/util/storage';
import Container from '@/components/Container';

// -- multiple langs TODO --
const langs = {
  zh,
  vi,
  en,
  hu,
  th,
  ms,
  'pt-BR': pt_BR,
  ru: ru
};

const getTheme = (userTheme: string | undefined, defaultTheme: ThemeTypes): ThemeTypes => {
  if (userTheme && Object.values(ThemeTypes).includes(userTheme as ThemeTypes)) {
    return userTheme as ThemeTypes;
  }
  return defaultTheme;
};

const isLangKey = (key: any): key is keyof typeof langs => {
  return key in langs;
};

message.config({
  maxCount: 1,
});
const BaseLayout = (props: any) => {
  const { isAuth, children } = props;
  const userTheme = useSelector((state: any) => {
    return getTheme(state.User?.data?.[0]?.themeType, defaultTheme);
  });

  console.log('defaultTheme---', userTheme);
  const mulLangLocalTagRemark = getLocal(LocalNameEnum.mulLangLocalTagRemark);
  const local = isLangKey(mulLangLocalTagRemark) ? langs[mulLangLocalTagRemark] : langs['en'];

  useEffect(() => {
    loadGlobalVar(userTheme);
  }, [userTheme]);

  const publicCapture = useCallback(e => {
    // 埋点
    toReport(e);
  }, []);

  return (
    <ConfigProvider
      locale={local}
      theme={{
        ...(antDthemes[userTheme] || {}),
      }}
    >
      <PermissionsProvider>
        <div className="ispWrapper" onClickCapture={publicCapture}>
          {isAuth && <Menu {...props}></Menu>}
          <Container>{children}</Container>
          <NewFooter />
        </div>
      </PermissionsProvider>
    </ConfigProvider>
  );
};

export default BaseLayout;
