import moment from 'moment';
import store from '@/redux/stroe';

// 检查元素是否在某个元素里，与 jQuery.contains 等同
export function contains(container, target) {
  if (target) {
    while ((target = target.parentNode)) {
      if (container === target) {
        return true;
      }
    }
  }
  return false;
}

// 获取元素到页面左边与顶部的距离，与 jQuery().offset() 结果等同
export function offset(elem) {
  const offset = { left: 0, top: 0 };
  while (elem) {
    offset.left += elem.offsetLeft;
    offset.top += elem.offsetTop;
    elem = elem.offsetParent;
  }
  return offset;
}

export function isWindow(elem) {
  return elem != null && elem === elem.window;
}

export function getClientWidth(elem) {
  if (isWindow(elem) || elem.nodeType === 9) {
    elem = document.documentElement;
  }
  return elem.clientWidth;
}

export function getClientHeight(elem) {
  if (isWindow(elem) || elem.nodeType === 9) {
    elem = document.documentElement;
  }
  return elem.clientHeight;
}

export function getScrollHeight(elem) {
  if (isWindow(elem) || elem.nodeType === 9) {
    elem = document.documentElement;
  }
  return elem.scrollHeight;
}

export function scrollTop(elem, value) {
  let win;
  if (isWindow(elem)) {
    win = elem;
  } else if (elem.nodeType === 9) {
    win = elem.defaultView;
  }

  if (value === undefined) {
    return win ? win.pageYOffset : elem.scrollTop;
  }

  if (win) {
    win.scrollTo(0, value);
  } else {
    elem.scrollTop = value;
  }
}

// 检查元素是否在可视区内
export function isElementInViewport(elem, ahead = 1) {
  const st = scrollTop(window);
  const ch = getClientHeight(window);
  const elemTop = offset(elem).top;
  const elemHeight = elem.offsetHeight / ahead;

  return elemTop + elemHeight > st && elemTop < st + ch;
}

export function scrollTo(to, element = window, speed = 0.3) {
  // to 不能小于 0，不能大于最大滚动距离
  to = Math.max(0, Math.min(getScrollHeight(element) - getClientHeight(element), to));

  clearInterval(element.TIMER_OF_SRCOLL_TO);
  element.TIMER_OF_SRCOLL_TO = setInterval(function() {
    let st = scrollTop(element);
    const position = (to - st) * speed;

    st += st > to ? Math.floor(position) : Math.ceil(position);

    scrollTop(element, st);

    if (st < to + 1 && st > to - 1) {
      clearInterval(element.TIMER_OF_SRCOLL_TO);
      scrollTop(element, to);
    }
  }, 30);
}

export function uniqByArray(target, filterArray, iteratee) {
  if (!Array.isArray(target) || !Array.isArray(filterArray)) {
    return [];
  }

  if (!filterArray.length) {
    return target;
  }

  if (!iteratee || typeof iteratee !== 'string') {
    throw new Error('[uniqByArray]: The third parameter is required and must be a string.');
  }

  return target.filter(item => {
    let result = true;
    const value = item[iteratee];
    filterArray.some(arr => {
      if (arr[iteratee] === value) {
        result = false;
        return true;
      }
    });
    return result;
  });
}

export function unPick(obj = {}, needlesskeys = []) {
  const newObj = {};
  for (const key in obj) {
    if (needlesskeys.indexOf(key) === -1) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function getUrlQuerySign(urlString) {
  return urlString.indexOf('?') === -1 ? '?' : '&';
}
export function getUrlQuery(name, url = window.location.search) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = reg.exec(url);
  if (r != null) return unescape(r[2]);
  return null;
}
export const dateHandler = {
  addZero(num) {
    return num > 9 ? num : `0${num}`;
  },
  getDate(time = Date.now()) {
    const date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}.${this.addZero(month)}.${this.addZero(day)}`;
  },
  getHoursAndMinutes(time = Date.now()) {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return { hours, minutes };
  },
  getHomecomingDate(homecoming) {
    const date = this.getDate(homecoming.start_time);
    const s = this.getHoursAndMinutes(homecoming.start_time);
    const e = this.getHoursAndMinutes(homecoming.end_time);
    const snippetOne = `${this.addZero(s.hours)}:${this.addZero(s.minutes)}`;
    const snippetTwo = `${this.addZero(e.hours)}:${this.addZero(e.minutes)}`;
    return `${date} ${snippetOne}-${snippetTwo}`;
  },
};

export function supportWebP() {
  const elem = document.createElement('canvas');

  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
}

// 返回相应的媒体查询缩放比例
export function getMediaRate() {
  const windowWidth = window.innerWidth;
  let rate = 1;

  if (windowWidth < 1279) {
    rate = 12 / 14;
  } else if (windowWidth < 1045) {
    rate = 10 / 14;
  }

  return rate;
}
//获取url参数
export function getUrlsQuery(name, url = window.location.search) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = url.substr(1)?.match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

export function getPrice(price) {
  if (!price) {
    return {
      int: '0',
      float: '00',
    };
  }
  const arr = typeof price === 'number' ? String(price.toFixed(2)).split('.') : price.split('.');
  const int = arr[0] || '0';
  const float = arr[1] || '00';
  return {
    int,
    float,
  };
}

/*
 * 判断obj是否为一个整数
 */
function isInteger(obj) {
  return Math.floor(obj) === obj;
}
/*
 * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
 * @param floatNum {number} 小数
 * @return {object}
 *   {times:100, num: 314}
 */
export function toInteger(floatNum) {
  const ret = { times: 1, num: 0 };
  if (isInteger(floatNum)) {
    ret.num = floatNum;
    return ret;
  }
  const strfi = floatNum + '';
  const dotPos = strfi.indexOf('.');
  const len = strfi.substr(dotPos + 1).length;
  const times = Math.pow(10, len);
  const intNum = Number(floatNum.toString().replace('.', ''));
  ret.times = times;
  ret.num = intNum;
  return ret;
}

export function timestampToTime(timestamp) {
  const date = new Date(); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

  const strDate = Y + M + D + h + m + s;
  return strDate;
}

export function formatMoney_old(money, showCurrency = true) {
  return (
    (showCurrency ? '$' : '') +
    parseFloat((money || '0').toString())
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
}
export function formatMoney(money, showCurrency = true) {
  if (money === null || money === undefined) {
    return money
  }
  let floatNum = 2; //默认精度处理
  let formattedMoney = parseFloat((money || '0').toString()).toFixed(floatNum).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  if (formattedMoney.endsWith('.00')) {
    formattedMoney = formattedMoney.replace('.00', ''); // 去掉小数点和两位小数
  }
  return (showCurrency ? '$' : '') + formattedMoney;
}
export function cnyMoney_old(money, showCurrency = true) {
  return (
    (showCurrency ? '¥' : '') +
    parseFloat((money || '0').toString())
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
}
export function cnyMoney(money, showCurrency = true, symbol) {
  if (money === null || money === undefined) {
    return money
  }
  
  let floatNum = 2; //默认精度处理
  let formattedMoney = parseFloat((money || '0').toString()).toFixed(floatNum).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  if (formattedMoney.endsWith('.00')) {
    formattedMoney = formattedMoney.replace('.00', ''); // 去掉小数点和两位小数
  }
  return (showCurrency ? symbol || '¥' : '') + formattedMoney;
}
/**
 * 获取页面url参数
 */
export function GetRequest() {
  const url = location.search;
  const theRequest = new Object();
  if (url.indexOf('?') != -1) {
    const str = url.substr(1);
    const strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
    }
  }
  return theRequest;
}
//搜索专用
export const GetRequestV2 = () => {
  const url = location.search; //获取url中"?"符后的字串
  // const theRequest = new Object();
  const theRequest: any = {};
  if (url.indexOf('?') != -1) {
    const str = url.substr(1);
    const strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      const st = decodeURIComponent(strs[i].split('=')[1]);
      theRequest[strs[i].split('=')[0]] = st.split('|')[0];
    }
  }
  return theRequest;
};

export function getUrlParams(name, str) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  if (str.indexOf('?') === -1) return null;
  const r = str.substr(1)?.match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

/**
 * 判断参数是否为空
 * @param param
 */
export function isEmpty(param) {
  const nullArr = ['', null, undefined];
  if (nullArr.includes(param)) {
    return true;
  }
  return false;
}

/**
 * 过滤参数
 * @param params 需要被过滤的对象
 */
export function filterParams(params) {
  const newVlaue = {};
  // eslint-disable-next-line no-undef
  Reflect.ownKeys(params).forEach(attr => {
    if (!isEmpty(params[attr])) {
      if (typeof params[attr] === 'string') {
        newVlaue[attr] = params[attr].trim();
      } else {
        newVlaue[attr] = params[attr];
      }
    }
  });
  return newVlaue;
}

export function goTop(animated = true) {
  window.scrollTo({
    top: 0,
    behavior: animated ? 'smooth' : 'auto',
  });
}

export function duration(timestamp) {
  const oneMinute = 60;
  const oneHour = 60 * oneMinute;
  const oneDay = 24 * oneHour;
  const days = Math.floor(timestamp / oneDay);
  let remain = timestamp - days * oneDay;
  const hours = Math.floor(remain / oneHour);
  remain = timestamp - days * oneDay - hours * oneHour;
  const minutes = Math.floor(remain / oneMinute);
  if (days > 0) {
    return `${days}天${hours}时`;
  } else {
    return `${hours}时${minutes}分`;
  }
}
export function getClstag(id, typeName) {
  return `h|keycount|${typeName}|${id}`;
}
export function filterBannerAd(data: any) {
  let map = data[0]?.adPositionHtml;
  map = map?.pos_005?.materialList;
  if (map) {
    return map.map((item: any) => {
      return {
        graphLink: `${item.graphLink}`,
        extLink: `${item.extLink}`,
        id: `${item.id}`,
      };
    });
  }
  return null;
}
export function filterFloor(data: any) {
  // const map = data[0]?.adPositionHtml?.pos_005?.materialList;

  const map = data.map((item: any) => {
    return item.adPositionHtml?.pos_005?.materialList;
  });
  return map || null;
}

//获取sku 存入log
export function getLogSkus(skus) {
  const mkuIds = skus.map(sku => {
    return sku.thirdSkuId;
  });
  return mkuIds.join('#');
}
export function getCookie(name) {
  const arr = document.cookie?.match(new RegExp(`(^| )${name}=([^;]*)(;|$)`));
  if (arr != null) {
    return arr[2];
  }
  return '';
}

export function initArea(areaId = 'jdarea') {
  setTimeout(() => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    $(`#${areaId}`).area({
      scopeLevel: 4,
      hasCssLink: false,
      cookieMapping: { allLocal: 'new_isp_address' },
      // @ts-ignore
      // eslint-disable-next-line no-undef
      initArea: readCookie('new_isp_address') || '1_72_2819_0.123',
      cookieOpts: { path: '/', domain: 'jd.com', expires: 30 },
      onChange: function() {
        location.reload();
      },
    });
  }, 1000);
}

//判断是否是IE浏览器 && 是否是IE11浏览器
export function isIE() {
  const userAgent = navigator.userAgent;
  return userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
}
//将后台给的css，动态插入到head节点上
export function dynamicAddCss(divList: any) {
  divList &&
    divList.forEach((item: any) => {
      const link = document.createElement('link');
      link.setAttribute('type', 'text/css');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('addDy', true);
      link.setAttribute('href', (item && item.getAttribute('cssurl')) || '');
      document.head.appendChild(link);
    });
}
export function deleteDynamicCss() {
  const nodeList = document.querySelectorAll('head link[addDy]');
  nodeList &&
    nodeList.forEach((node: any) => {
      node.parentNode.removeChild(node);
    });
}
export function getUrlMkuid() {
  const url = window.location.href;
  const index = url.lastIndexOf('/');
  const str = url.substring(index + 1, url.length);
  return str;
}
export const getQueryVariable = (variable: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};
export const clearParam = (key: string, value: string) => {
  const { origin, pathname } = window.location;
  window.location.href = origin + pathname + '?' + key + '=' + value;
};
export const insertParam = (key: string, value: string) => {
  key = decodeURIComponent(key);
  value = decodeURIComponent(value);

  const kvp: any = document.location.search.substr(1).split('&');
  if (kvp == '') {
    document.location.search = '?' + key + '=' + value;
  } else {
    let i = kvp.length;
    let x;
    while (i--) {
      x = kvp[i].split('=');

      if (x[0] == key) {
        x[1] = value;
        kvp[i] = x.join('=');
        break;
      }
    }
    //如果不是设置分页，初始化为第一页
    if (key != 'pageNo') {
      let i = kvp.length;
      let x;
      while (i--) {
        x = kvp[i].split('=');

        if (x[0] == 'pageNo') {
          x[1] = 1;
          kvp[i] = x.join('=');
          break;
        }
      }
    }

    if (i < 0) {
      kvp[kvp.length] = [key, value].join('=');
    }

    //this will reload the page, it's likely better to store this until finished
    // document.location.search = kvp.join("&");
  }
  let i = kvp.length;
  let x;
  let newUrl = '';
  while (i--) {
    x = kvp[i].split('=');

    if (x[1] == '') {
      x[1] = value;
      kvp.splice(i, 1);
      break;
    }
  }
  newUrl = kvp.length > 0 ? '?' + kvp.join('&') : '';
  const { origin, pathname } = window.location;
  if (key == 'priceFilter') {
    window.location.href = origin + pathname + newUrl;
  } else {
    window.history.pushState({}, '', origin + pathname + newUrl);
  }
};
//设置整个paramFilter2参数
export const insertParam2 = (key: string, value: string) => {
  key = decodeURIComponent(key);
  value = decodeURIComponent(value);
  const arrkey = getQueryVariable(`extAttrList`) ? decodeURIComponent(getQueryVariable(`extAttrList`)).split(',') : [];
  const isU = arrkey.find(arrkey => arrkey.split('|')[0] == value.split('|')[0]);
  const isOneV = arrkey.find(arrkey => arrkey.split('|')[0] == value);
  if (key == 'extAttrList' && arrkey.length > 0) {
    if (!isU) {
      arrkey.push(value);
    } else {
      arrkey.splice(
        arrkey.findIndex(e => e.split('|')[0] == value.split('|')[0]),
        1,
      );
      arrkey.push(value);
    }
    value = arrkey;
  }

  if (key == 'extAttrList' && arrkey.length > 0 && isOneV) {
    arrkey.splice(
      arrkey.findIndex(e => e.split('|')[0] == value),
      1,
    );
    value = arrkey;
  }

  const kvp: any = document.location.search.substr(1).split('&');

  if (kvp == '') {
    document.location.search = '?' + key + '=' + value;
  } else {
    let i = kvp.length;
    let x;
    while (i--) {
      x = kvp[i].split('=');
      //找到pageNo，初始化为第一页
      if (x[0] == 'pageNo') {
        x[1] = 1;
        kvp[i] = x.join('=');
      }

      if (x[0] == key) {
        // x[1] = encodeURIComponent(value);
        x[1] = value;
        kvp[i] = x.join('=');
        break;
      }
    }

    if (i < 0) {
      kvp[kvp.length] = [key, encodeURIComponent(value)].join('=');
    }

    //this will reload the page, it's likely better to store this until finished
    // document.location.search = kvp.join("&");
  }
  let i = kvp.length;
  let x;
  let newUrl = '';
  while (i--) {
    x = kvp[i].split('=');
    if (x[1] == '') {
      x[1] = value;
      kvp.splice(i, 1);
      break;
    }
  }

  newUrl = kvp.length > 0 ? '?' + kvp.join('&') : '';

  // const { origin, pathname } = window.location;
  // window.location.href = origin + pathname + newUrl;
  const { origin, pathname } = window.location;
  if (kvp.length == 0) {
    window.location.href = origin + pathname;
  } else {
    window.history.pushState({}, '', origin + pathname + newUrl);
  }
};

export const getPriceString = (price: any) => {
  return price ? price.split('.') : [];
};
//获取pvid
export const getPvId = () => {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};
//获取logid
export const getLogId = () => {
  return new Date().getMilliseconds() + '.' + Math.floor(Math.random() * 9000) + 1000;
};
export const Setcookie = (name, value) => {
  //设置名称为name,值为value的Cookie
  const expdate = new Date(); //初始化时间
  expdate.setTime(expdate.getTime() + 30 * 60 * 1000); //时间单位毫秒
  document.cookie = name + '=' + value + ';expires=' + expdate.toUTCString() + ';domain=.jd.com;path=/';
  //即document.cookie= name+"="+value+";path=/";  时间默认为当前会话可以不要，但路径要填写，因为JS的默认路径是当前页，如果不填，此cookie只在当前页面生效！
};
// 搜索词高亮替换
export const replaceString = (parentStr, searchText) => {
  const regSearchText = searchText.replace(
    /[[\]{};',./:"<>?!@#$%^&*()_+【】、；‘，。、{}|：”“《》？！@#￥%……&*（）——+]*/g,
    '',
  ); //过滤特殊字符
  if (regSearchText) {
    const res = new RegExp('(' + regSearchText + ')', 'g');
    parentStr = parentStr?.replace(res, "<span style='color:#e50c00;'>" + regSearchText + '</span>');
    return parentStr;
  } else {
    return parentStr;
  }
};

export const checkSortType = () => {
  const params = GetRequest();
  //（1=默认排序；2=价格排序；3=京东物流；4=有货；5=物料编码）
  const sortType = params?.sortType || ''; //价格排序
  const jdLogistics = params?.jdLogistics || ''; //京东物流
  const redisstore = params?.redisstore || ''; //有货
  const mcSearch = params?.mcSearch || ''; //物料编码

  const sortList = [];
  sortType ? sortList.push(2) : '';
  jdLogistics ? sortList.push(3) : '';
  redisstore ? sortList.push(4) : '';
  mcSearch ? sortList.push(5) : '';
  Number(sortList.length) === 0 ? sortList.push(1) : '';

  return sortList.join(',');
};

//获取时间 本周、本月、本季、全年、自定义
export const getStandardDate = (type: any) => {
  let startDate = '';
  const endDate = moment(new Date()).format('YYYY-MM-DD');
  switch (type) {
    case 1:
      //本周开始时间
      // eslint-disable-next-line no-case-declarations
      const weekOfday = new Date().getDay();
      if (weekOfday == 1) {
        startDate = moment()
          .add(-1, 'W')
          .format('YYYY-MM-DD');
      } else {
        startDate = moment()
          .startOf('week')
          .add(-1, 'day')
          .format('YYYY-MM-DD');
      }
      break;
    case 3: //本月
      if (
        moment()
          .startOf('month')
          .format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')
      ) {
        startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
      } else {
        startDate = moment()
          .startOf('month')
          .format('YYYY-MM-DD');
      }
      break;
    case 5: //本季
      if (
        moment()
          .startOf('quarters')
          .format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')
      ) {
        startDate = moment()
          .add('-1', 'Q')
          .format('YYYY-MM-DD');
      } else {
        startDate = moment()
          .startOf('quarter')
          .format('YYYY-MM-DD');
      }
      break;
    case 6: //全年
      if (
        moment()
          .startOf('year')
          .format('YYYY-MM-DD') == moment(new Date()).format('YYYY-MM-DD')
      ) {
        startDate = moment()
          .add(-1, 'year')
          .format('YYYY-MM-DD');
      } else {
        startDate = moment()
          .startOf('year')
          .format('YYYY-MM-DD');
      }
      break;
    default:
      //本周
      // eslint-disable-next-line no-case-declarations
      const weekDay = new Date().getDay();
      if (weekDay == 1) {
        startDate = moment()
          .add(-1, 'W')
          .format('YYYY-MM-DD');
      } else {
        startDate = moment()
          .startOf('week')
          .add(-1, 'day')
          .format('YYYY-MM-DD');
      }
      break;
  }
  return { startDate, endDate };
};
// 递归扁平化publicPromise权限列表
export const flattenPublicPromise = (pp = []) => {
  const _arr = [];
  const loop = (oobj, narr) => {
    if (oobj.children) {
      narr.push(...oobj.children);
      oobj.children.forEach(e => {
        loop(e, narr);
      });
    }
    return narr;
  };

  pp &&
    pp.forEach(e => {
      loop(e, _arr);
    });

  return _arr;
};

// 判断资源是否在新权限列表中
export const useNewPromise = resourceCode => {
  const userPublicPromise = store.getState()?.User?.publicPromise ?? [];
  const upp = userPublicPromise.find(e => e.resourceCode === resourceCode);

  if (upp) return true;
};

/*
 * 个人权限筛选公共方法
 * [resourceCode]: String 运营平台配置的权限资源码
 * return<Boolean> true/false 有权限/无权限
 */
export const findPublucPromise = resourceCode => {
  // 如果个人promiseWhite为true, 走新权限
  const promiseWhite = store.getState()?.User?.promiseWhite ?? false;
  if (!promiseWhite) return true;

  // 新权限判断逻辑，在权限列表中获取对应权限
  const userPublicPromise = store.getState()?.User?.publicPromise ?? [];
  const upp = userPublicPromise.find(e => e.resourceCode === resourceCode);
  return upp?.selected ?? false;
};
export const getValues = (values: any) => {
  const newValues: any = [];
  values &&
    values?.forEach((item: any) => {
      for (const key in item) {
        newValues.push({
          key: key,
          value: item[key],
        });
      }
    });
  return newValues;
};


export function getJdiOSFile (path) {
  return `https://jdios.jdindustry.com/${path}`
}

export const fullUrlGen = (url, params) => {
  return `${url}?${new URLSearchParams(params).toString()}`;
};