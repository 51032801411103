/*
 * @Author: weiyanna weiyanna@jd.com
 * @Date: 2022-05-16 10:58:05
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2024-09-02 14:23:28
 * @FilePath: /isp_International/src/pages/SearchList/components/GoodsList/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState } from 'react';
import cookies from 'react-cookies';
import Carousel from '../Carousel';
import { replaceString, checkSortType, getCookie } from '@/util';
import { formatMoney } from '@/util/index';
import { isGuest } from '@/util/commonUtils';
import PaginationJD from '@/components/PaginationJD';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Page from '@/components/SearchList/Page';
import { useTranslation } from 'react-i18next';
import { dispatchAnnounceData } from '@/redux/action';
import Empty from '@/components/common/Empty';
import { Space, Spin } from 'antd';
import Replenishment from '@/components/Replenishment';
const PageC = memo(Page);
import { getJdiOSFile } from '@/util/index';
import Price from '@/components/Atomics/Prices/price';
import Des from '@/components/Atomics/Des/des';
import Tags from '@/components/Atomics/Tags/tags';
export interface IAppProps {
  pageInfo: any;
  changeGoodData: any;
  chooseGood: any;
  chooseGoodItem: any;
  chooseGoodIndex: any;
  addCarBe: Function;
  isWideScreen: boolean;
  updataData: any;
  searchedWord: string;
}
const Index: React.FC<IAppProps> = (props: any) => {
  const { changeGoodData, chooseGoodItem, chooseGoodIndex, chooseGood, addCarBe, searchedWord, updataData } = props;
  const red_goods_data: any = useSelector((state: any) => {
    return state.SearchList;
  });

  const {
    pageSize,
    selectParams,
    params,
    pageInfo,
    extAttrList,
    brandList,
    priceRangeList,
    isShowJDPrice,
  } = red_goods_data;
  const [isShow, setIsShow] = useState(false); //展示
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedShopInfo, setSelectedShopInfo] = useState<any>(null); //选中的商品
  const userInfo: any = useSelector((state: any) => {
    return state?.User?.data[0];
  });
  const goTop = () => {
    const anchorElement = document.getElementById('__position');
    if (anchorElement) {
      anchorElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  // 新增辅助函数，根据 item 状态渲染库存状态提示
  const renderStockStatus = (item: any) => {
    if (item?.stockFlag === 4) {
      return (
        <div className={`${styles.stock} ${styles.stock_purchasing}`}>
          {TLT('采购中，现在可下单')}
        </div>
      );
    }
    if (item?.remainNum === 0) {
      return <div className={`${styles.stock}`}>{TLT('无货')}</div>;
    }
    if (item?.remainNum > 0 && item?.remainNum <= 200) {
      return (
        <div
          className={`${styles.stock} ${styles.stock_surplus100}`}
          dangerouslySetInnerHTML={{
            __html: TLT('库存仅剩XX件').replace(
              'XX',
              `<span class=${styles.remainNum}>${item?.remainNum}</span>`
            ),
          }}
        ></div>
      );
    }
    return null;
  };

  return (
    <div className={styles.content}>
      <PageC updataData={updataData} from={'SearchListCable'} searchWhite={false}></PageC>
      <div className={`${styles.larger_img}`}>
        <div className={`${styles.gl_warp}`} id="gl_warp">
          {changeGoodData?.length > 0 &&
            changeGoodData?.map((item: any, index: any) => {
              return (
                <div className={`${styles.gl_item}`} key={item.sku || index}>
                  {/* 商品图片 */}
                  <Carousel
                    mainData={item}
                    chooseGood={chooseGood}
                    userInfo={userInfo}
                    noStock={!item?.deliveryDate && item?.remainNum === 0}
                    hideSmallImage={true}
                  >
                    {renderStockStatus(item)}
                  </Carousel>

                  {/* 商品名称 */}
                  <h3
                    className={`${styles.title_1} productNameText`}
                    data-track={JSON.stringify({
                      eventId: 'ProductList_ProductNameClk',
                      eventParam: {
                        mku: item.showAttrs.sku
                      }
                    })}
                    onClick={() => window._openPage(`/sku/${item.showAttrs.sku}`, '_blank')}
                    dangerouslySetInnerHTML={{
                      __html: replaceString(item.showAttrs.wareName, searchedWord),
                    }}
                  ></h3>

                  <div className={styles.productCode} title={item.showAttrs.sku || ''}>
                    <Des label={TLT(`商品编码`)} text={item?.showAttrs?.sku} fontSize='12px'></Des>
                  </div>

                  {/* 跨进本土展示价格样式不一致  需跟产品讨论考虑是否保持一致 */}
                  {item.showAttrs.price != -1 ? (
                    <div className={`${styles.ent_price}`}>
                      {Number(item?.saleState?.saleState) === 1 ? (
                        <Price
                          type={1}
                          symbol={item?.showCurrency?.symbol}
                          salePrice={formatMoney(item?.showCurrency?.salePrice, false)}
                          price={formatMoney(item?.showCurrency?.price, false)}
                        ></Price>
                      ) : (
                        <div className={styles.noPrice}>{TLT(`无法查看采购价`)}</div>
                      )}
                    </div>
                  ) : (
                    <div className={styles.noPrice}>{TLT(`暂无报价`)}</div>
                  )}

                  <div className={styles.tagsContainer}>
                    {
                      item?.featureTagMap?.['48-hour-shipping'] && (
                        <span style={{ marginRight: '5px' }}>
                          <Tags type='48h' />
                        </span>
                      )
                    }

                    {item?.sourceCountryCode == 'CN' && (
                      <div className={styles.productTag}>
                        <img className={styles.tagIcon} src={getJdiOSFile('mubla1/195354135364234883.png')} />
                        <span className={styles.tagSection}>{TLT('跨境')}</span>
                      </div>
                    )}
                  </div>

                  <div className={styles.operations}>
                    {/* 非游客 本土或者跨境且有库存才展示  */}
                    {!isGuest() && !(!item?.deliveryDate && item?.remainNum === 0) &&  (
                      <div className={`${styles.operationBtn}`}>
                        <div
                          className={`${styles.operationBtnContainer}`}
                          data-track={JSON.stringify({
                            eventId: 'ProductList_AddCartClk',
                            eventParam: {
                              mku: item.showAttrs.sku
                            }
                          })}
                          onClick={() => {
                            if (
                              chooseGoodItem.length > 0 &&
                              chooseGoodItem[chooseGoodIndex].sku === item.showAttrs.sku
                            ) {
                              //@ts-ignore
                              addCarBe(
                                chooseGoodItem[chooseGoodIndex]?.saleState?.saleState,
                                chooseGoodItem[chooseGoodIndex],
                              );
                            } else {
                              addCarBe(item?.saleState?.saleState, item);
                            }
                            log('jdisp', 'Search_AddtoCart');
                          }}
                        >
                          <div className={styles.cartImage}></div>
                          <span className={styles.operationInstruction}>{TLT(`加入购物车`)}</span>
                        </div>
                      </div>
                    )}

                    {/* {item?.remainNum === 0 && (
                      <div className={`${styles.operationBtn}`}>
                        <div
                          className={`${styles.operationBtnContainer}`}
                          onClick={() => {
                            setIsShow(true);
                            setSelectedShopInfo({
                              mkuId: item.sku,
                              img: item.img,
                              productName: item.wareName,
                            });
                          }}
                        >
                          <span className={styles.operationInstruction}>{TLT(`申请补货`)}</span>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              );
            })}
          {changeGoodData.length <= 0 &&
            (extAttrList.length > 0 || brandList.length > 0 || priceRangeList.length > 0) && <Empty type="reset" />}
        </div>

        <div className={`${styles.pagination}`}>
          {changeGoodData?.length > 0 && pageInfo && pageInfo.pageIndex ? (
            <PaginationJD
              hideOnSinglePage
              current={pageInfo.pageIndex}
              total={pageInfo.maxResultCount}
              pageSize={pageSize}
              showSizeChanger={false}
              trackInfo={{
                eventId: 'ProductList_PageBottomClk'
              }}
              onChange={(_page: any) => {
                const paramsData = {
                  ...params,
                  pageNo: _page,
                };
                dispatch(dispatchAnnounceData(paramsData, true, selectParams));
                goTop();
              }}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <Replenishment isVisible={isShow} setIsVisible={isVisble => setIsShow(isVisble)} shopInfo={selectedShopInfo} />
    </div>
  );
};
export default Index;
