import React from 'react';
import { useRecoilValue } from '@/pages/SkuDetailV2/hooks';
import PropertyList from '@/components/PropertyList';
import styles from './index.module.scss';

const Index = () => {
  const _productAttr = useRecoilValue('productAttr');
  const productAttr = Object.entries(_productAttr ? _productAttr : {}).map(([key, value]) => ({
    key,
    value,
  }));

  return (
    <div className={styles.m} id="parameter">
      <div className={styles.m_box}>
        {productAttr && productAttr?.length > 0 && <PropertyList List={productAttr} paddingLeft="0" />}
      </div>
    </div>
  );
};

export default Index;
