/*
 * @Author: wuzhonghai1 wuzhonghai1@jd.com
 * @Date: 2024-08-16 14:05:03
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @LastEditTime: 2025-03-21 20:51:01
 * @FilePath: /isp_International/src/api/newApi/materialcode.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from '../axios';


//获取物料编码列表接口
export function getMaterialCodeList(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/mkuMaterial/page', params, false, true).then(
        res => {
          console.log(res, 'confirmOgetMaterialCodeListrder');
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

//查询物料编码详情
export function getMaterialDetail(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/mkuMaterial/detail', params, false, true).then(
      res => {
        console.log(res, 'getMaterialDetail');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//保存物料编码
export function saveMaterialCode(params: any = {}) {
    return new Promise((resolve, reject) => {
      http('jsonpost', '/jdig/mkuMaterial/save', params, false, true).then(
        res => {
          console.log(res, 'getMaterialDetail');
          resolve(res);
        },
        error => {
          console.log('网络异常~', error);
          reject(error);
        },
      );
    });
}

export function updateMaterialCode(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/mkuMaterial/update', params, false, true).then(
      res => {
        console.log(res, 'getMaterialDetail');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}
//校验是否同一个物料编码绑定多个MKU
export function isExistMaterialCode(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/mkuMaterial/isExist', params, false, true).then(
      res => {
        console.log(res, 'getMaterialDetail');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}


//保存物料编码
export function delMaterialCode(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/mkuMaterial/delete', params, false, true).then(
      res => {
        console.log(res, 'getMaterialDetail');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//批量删除物料编码
export function batchDelMaterialCode(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('jsonpost', '/jdig/mkuMaterial/batchDelete', params, false, true).then(
      res => {
        console.log(res, 'getMaterialDetail');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}

//批量删除物料编码
export function downloadMaterialTemplate(params: any = {}) {
  return new Promise((resolve, reject) => {
    http('get', '/jdig/mkuMaterial/batchAddTemplate', params, false, true).then(
      res => {
        console.log(res, 'getMaterialDetail');
        resolve(res);
      },
      error => {
        console.log('网络异常~', error);
        reject(error);
      },
    );
  });
}










