import React, { useState, useEffect } from 'react';
import { Tabs, Badge, ConfigProvider, Row } from 'antd';
const { TabPane } = Tabs;
import styles from './index.module.scss';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import { Link } from 'react-router-dom';
import iconDelete from '@/assets/images/orderList/icon_delete.svg';
import iconQuestion from '@/assets/images/orderList/icon_question.svg';
import iconWarning from '@/assets/images/orderList/icon_warning.svg';
import iconVsp from '@/assets/images/orderList/icon_vsp.png';
import { OrderStatus } from '@/components/Order';
import { getVspStatus } from '@/api/orderList/api';
import { useTranslation } from 'react-i18next';
import { getOrderStatusList } from '@/api/newApi/order';

const Tab: React.FC<{
  isAudit: boolean;
  isDelete: boolean;
  status: OrderStatus;
  auditQueryType: string;
  showCompleteAudit: boolean;
  orderCount: any;
  onOrderTabChange: (activeKey: OrderStatus) => void;
  onAuditTabChange: (activeKey: string) => void;
}> = ({
  isAudit,
  isDelete,
  status,
  auditQueryType,
  showCompleteAudit,
  orderCount,
  onOrderTabChange,
  onAuditTabChange,
}) => {
  const { t } = useTranslation();
  const [isVspUser, setVspUser] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(auditQueryType);
  const [tabList, setTablist] = useState<any[]>([]);

  useEffect(() => {
    getAllstatus();
  }, []);

  // const checkVspStatus = () => {
  //   getVspStatus({}).then((res: any) => {
  //     setVspUser(res?.value ?? false);
  //   });
  // };

  // useEffect(() => {
  //   checkVspStatus();
  // }, []);

  /**
   * 获取所有状态
   */
  const getAllstatus = () => {
    getOrderStatusList({}).then((res: any) => {
      if (res?.success) {
        setTablist(
          [
            {
              label: TLT(`全部`),
              value: 'all',
              count: 0,
              code: 'all',
            },
          ].concat(res.value),
        );
      }
    });
  };

  const getTabKey = (code: keyof typeof OrderStatus) => {
    //全部
    if (code === 'all') {
      return OrderStatus.all;
    }
    //已提交
    else if (code === 'waitOperation') {
      return OrderStatus.committed;
    }
    //已提交
    else if (code === 'waitApproval') {
      return OrderStatus.waitingApprove;
    } else if (code === 'delivered') {
      return OrderStatus.shipping;
    } else if (code === 'over') {
      return OrderStatus.complete;
    } else if (code === 'waitCustom') {
      return OrderStatus.waitingConfirm;
    } else if (code === 'Confirm Receipt') {
      return OrderStatus.confirmReceipt;
    } else {
      return OrderStatus[code];
    }
  };

  const tab = (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            horizontalItemGutter: 7
          },
        },
      }}
    >
      <Tabs
        defaultActiveKey={status}
        onChange={activeKey => {
          onOrderTabChange(activeKey as OrderStatus);
        }}
        className={styles.tab}
      >
        {tabList &&
          tabList?.map(ele => {
            return (
              <TabPane
                tab={
                  <div className={styles.tabTitle}>
                    {ele.label}
                    <Badge count={ele.count}></Badge>
                  </div>
                }
                key={getTabKey(ele.code)}
              />
            );
          })}
        {/* <TabPane
        clstag="h|keycount|myorder|1"
        tab={
          <div>
            {TLT(`全部`)}
            <Badge count={0}></Badge>
          </div>
        }
        key={OrderStatus.all}
      />
      <TabPane
        clstag="h|keycount|myorder|2"
        tab={
          <div>
            {TLT(`处理中`)}
            <Badge count={orderCount.waitOperation}></Badge>
          </div>
        }
        key={OrderStatus.committed}
      />
      <TabPane
        clstag="h|keycount|myorder|17"
        tab={
          <div>
            {TLT(`待确认`)}
            <Badge count={orderCount.waitCustom || 0}></Badge>
          </div>
        }
        key={OrderStatus.waitingConfirm}
      />
      <TabPane
        clstag="h|keycount|myorder|4"
        tab={
          <div>
            {TLT(`待审批`)}
            <Badge count={orderCount.waitApproval || 0}></Badge>
          </div>
        }
        key={OrderStatus.waitingApprove}
      />
      <TabPane
        clstag="h|keycount|myorder|3"
        tab={
          <div>
            {TLT(`发货中`)}
            <Badge count={orderCount.delivered}></Badge>
          </div>
        }
        key={OrderStatus.shipping}
      />
      <TabPane
        clstag="h|keycount|myorder|5"
        tab={
          <div>
            {TLT(`已完成`)}
            <Badge count={orderCount.over}></Badge>
          </div>
        }
        key={OrderStatus.complete}
      />
      <TabPane
        clstag="h|keycount|myorder|6"
        tab={
          <div>
            {TLT(`已取消`)}
            <Badge count={orderCount.cancel}></Badge>
          </div>
        }
        key={OrderStatus.cancel}
      /> */}
      </Tabs>
    </ConfigProvider>
  );

  return tab;
};

export default Tab;
