import React, { useState, useEffect } from 'react';
import { Button, message, Row } from 'antd';

import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  OrderStatus,
  orderStatus,
  buyAgain as buyAgain1,
  buyAgain2,
  pay,
  confirm,
  canCancel,
} from '@/components/Order';
import { duration, getLogSkus } from '@/util';
import { UserType } from '../SearchForm';
import Icon from '@/components/common/Icon';
import iconTime from '@/assets/images/orderList/icon_time.svg';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import Btn from '@/components/Atomics/Btn';
import ApproveProcess from '@/components/ApproveProcess';
import { customClickReportSimple } from '@/dataPort';

const OrderActions: React.FC<{
  order: any;
  isAudit: boolean;
  isDelete: boolean;
  // isVsp: boolean;
  auditQueryType: string;
  onApplyAftersale: () => void;
  onCancel: () => void;
  onApprove: () => void;
  onReject: () => void;
  onRestore: () => void;
  onCountDown: () => void;
  onReceiptCountDown?: () => void;
  isConfirm: boolean;
  orderConfirm: () => void;
}> = ({ order, isAudit, auditQueryType, onApprove, onReject, onCountDown, onReceiptCountDown }) => {
  const { t } = useTranslation();
  const [countDown, setCountDown] = useState<number>(order.cancelTime || 0);
  const [receiptCountDown, setReceiptCountDown] = useState<number>(0);
  const status = orderStatus(order);
  const currentUser: any = useSelector((state: any) => {
    return state?.User?.data?.[0] ?? {};
  });
  const userType: UserType = currentUser?.userType ?? 0;
  const [auditModalVisible, setAuditModalVisible] = useState<boolean>(false);

  const userPin: string = currentUser.pin;

  const [isNewOnceMorBuy, setIsNewOnceMorBuy] = useState<boolean>(currentUser?.isNewOnceMorBuy ?? false);

  const history = useHistory();
  const isFrom = 3; //1 慧采 2 订单回收 3 订单审批 0 订单列表

  const viewDetailButton = (order: any) => (
    <Btn
      clstag="h|keycount|myorder|18"
      className={styles.link}
      style={{ marginBottom: '8px' }}
      onClick={() => {
        (window as any).log('isp_orderlist', 'detail', userPin, order.jdOrderId);
        log('jdisp', 'ApprovalList_Approvaldetail');
        window._openPage(
          `/order/detail?id=${order.jdOrderId}&orderPin=${order.pin}&isFrom=${isFrom}&processId=${order.processId}`,
          '_blank',
        );
      }}
      rel="noopener noreferrer"
    >
      {TLT(`查看详情`)}
    </Btn>
  );

  const auditOrderActions = () => {
    return (
      <>
        {auditQueryType === '0' && (
          <>
            {(order?.operateMap?.showPassBtn ?? false) && (
              <Btn
                clstag="h|keycount|ordercenter|7"
                className={styles.link}
                style={{ marginBottom: '8px' }}
                type="text"
                onClick={onApprove}
              >
                {TLT(`通过`)}
              </Btn>
            )}
            {(order?.operateMap?.showRejectBtn ?? false) && (
              <Btn
                clstag="h|keycount|ordercenter|7"
                className={styles.link}
                type="text"
                style={{ marginBottom: '8px' }}
                onClick={onReject}
              >
                {TLT(`驳回`)}
              </Btn>
            )}
          </>
        )}

        {viewDetailButton(order)}

        <Btn
          onClick={() => {
            customClickReportSimple('ApprovalList_ApprovalDetails');
            // 母子帐号没有审批链路，不需要点开
            if (userType !== UserType.parentAccount && userType !== UserType.childAccount) {
              setAuditModalVisible(true);
            }
          }}
          className={`${styles.titleArrow}`}
        >
          {TLT(`审批详情`)}
          <i className={`${styles.crumbs_arrow}`}></i>
        </Btn>

        {auditModalVisible && (
          <ApproveProcess
            visible={auditModalVisible}
            order={order}
            type={'approve'}
            setAuditModalVisible={setAuditModalVisible}
            isShowApprove={true}
          />
        )}
      </>
    );
  };

  return auditOrderActions();
};

export default OrderActions;
