import React from 'react';
import Basic from './Basic';
import Breadcrumb from './Breadcrumb';

import styles from './index.module.scss';

const Index = ({ loading }: { loading: boolean }) => {
  return (
    <div className={styles.m}>
      <Breadcrumb />
      <div className={styles.mc}>
        <Basic loading={loading} />
      </div>
    </div>
  );
};

export default Index;
