/*
 * @Author: your name
 * @Date: 2021-12-28 17:53:05
 * @LastEditTime: 2024-08-06 17:21:20
 * @LastEditors: wuzhonghai1 wuzhonghai1@jd.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /isp_International/src/pages/ApprovalList/components/Goods/index.tsx
 */
import React, { useState } from 'react';
import { Row, Col, message, Tooltip } from 'antd';
import cookies from 'react-cookies';
import Icon from '@/components/common/Icon';
import styles from '../../index.module.scss';
import iconCopy from '@/assets/images/orderList/icon_copy.svg';
import iconX from '@/assets/images/orderList/icon_x.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { formatMoney, cnyMoney } from '@/util';
import Service from '@/components/ServiceStuff';
import { useTranslation } from 'react-i18next';
import ServiceStuff from '@/components/ServiceStuff';
import LazyLoad from 'react-lazyload';
import Des from '@/components/Atomics/Des/des';
import Tag from '@/components/Atomics/Tags/tags';
import { customClickReportSimple } from '@/dataPort';

const Goods: React.FC<{
  goods: any;
}> = ({ goods }) => {
  const { t } = useTranslation();
  const data = goods.serviceSkus || [];
  const [isLocalPro] = useState<boolean>(cookies.load('stationType') == '0'); // 当前站点是否本土
  let serviceData: any = [];
  if (data.length) {
    serviceData = goods?.extAttr?.giftAppendix ? data.concat(JSON.parse(goods?.extAttr?.giftAppendix)) : data;
  } else {
    serviceData = goods?.extAttr?.giftAppendix ? JSON.parse(goods?.extAttr?.giftAppendix) : [];
  }
  if (serviceData.length > 0) {
    serviceData.forEach((service: any) => {
      service.type = service.skuType;
      service.skuName = service.name;
      service.serviceSkuPrice = service.salesPrice;
    });
  }
  return (
    <div className={styles.productItemDetails}>
      <div className={styles.productItemInfoBox}>
        <LazyLoad>
          <img className={styles.productItemImage} src={`${goods.imgUrl}`} alt="skuImg" />
        </LazyLoad>
        <div className={styles.productItemInfo}>
          <div
            onClick={() => window._openPage(goods.skuId ? `/sku/${goods.skuId}` : '', '_blank')}
            className={`${styles.productItemName} ${styles.truncate}`}
            data-track={JSON.stringify({
              eventId: 'ApprovalDetail_ProductTitle',
            })}
          >
            {goods.name}
          </div>

          <div className={styles.productItemMetadata}>
            {goods.specifications && <Des label={TLT(`商品型号`)} text={goods.specifications} copy={false} />}

            <Des
              label={TLT(`商品编码`)}
              text={goods.skuId}
              copy={false}
              copyCb={() => {
                customClickReportSimple('ApprovalDetail_CopyMKU');
              }}
            />

            {goods.materialCode && <Des label={TLT(`物料编码`)} text={goods.materialCode} copy={false} />}

            <Des label={TLT(`含税价`)} text={goods.symbol + formatMoney(goods.taxSalePrice, false)} copy={false} />
            <Des label={TLT(`未税价`)} text={goods.symbol + formatMoney(goods.sellingPrice, false)} copy={false} />
            <Des label={TLT(`税率`)} text={goods.valueAddedTaxRate + '%'} copy={false} />
            {isLocalPro && (
              <Des
                copy={false}
                label={TLT(`是否报关`)}
                text={goods?.customsClearance == 1 ? TLT(`是`) : goods?.customsClearance == 0 ? TLT(`否`) : '-'}
              />
            )}
            {goods?.sourceCountryCode == 'CN' && <Tag type="cross" />}
          </div>
        </div>
      </div>
      <span className={styles.productItemquantity}>
        <span>x {goods.num}</span>
      </span>
    </div>
    // <div>

    //   <Row className={styles.borderTop}>
    //     <Col className={[styles.title1].join(' ')} style={{ paddingTop: '20px', textAlign: 'right' }}>
    //       {goods.isService ? (
    //         <div style={{ width: '75px', height: '75px' }} />
    //       ) : (
    //         <img
    //           src={`${goods.imgUrl}`}
    //           style={{ width: '75px', height: '75px', border: '1px solid rgba(0,0,0,0.07)' }}
    //         />
    //       )}
    //     </Col>
    //     <Col className={[styles.title2, styles.goodsCell].join(' ')} style={{ alignItems: 'flex-start' }}>
    //       <Tooltip
    //         color="#ffff"
    //         overlayInnerStyle={{ color: 'rgba(0,0,0,0.85)', fontSize: '12px' }}
    //         placement="bottomLeft"
    //         title={goods.skuName}
    //       >
    //         <a
    //           clstag="h|keycount|myorder|20"
    //           href={goods.skuId ? `/sku/${goods.skuId}` : undefined}
    //           className={styles.goodsName}
    //           rel="noreferrer"
    //           target="_blank"
    //           onClick={e => {
    //             // if (!goods.sku) {
    //             //   e.preventDefault();
    //             //   message.error(`商品${goods.sku}不在商品池`);
    //             // }
    //           }}
    //         >
    //           {goods?.name}
    //         </a>
    //       </Tooltip>

    //       <Row style={{ marginTop: '0px' }}>
    //         <span style={{ color: 'rgba(102,102,102,0.65)' }}>{TLT(`商品型号`)}: </span>
    //         <span style={{ marginLeft: '10px', color: 'rgba(51,51,51,0.85)' }}>{goods.specifications}</span>
    //       </Row>

    //       <Row style={{ marginTop: '0px' }}>
    //         <span style={{ color: 'rgba(102,102,102,0.65)' }}>{TLT(`商品编码`)}: </span>
    //         <span style={{ marginLeft: '10px', color: 'rgba(51,51,51,0.85)' }}>{goods?.skuId}</span>
    //       </Row>
    //     </Col>
    //     <Col className={[styles.title3, styles.goodsCell].join(' ')} style={{ alignItems: 'flex-start' }}>
    //       {!isLocalPro && (
    //         <Row style={{ alignItems: 'left' }}>
    //           <span>{goods.symbol + cnyMoney(goods.salesPrice, false)}</span>
    //           <Icon icon={iconX} style={{ marginLeft: '2px' }} />
    //           {goods.num}
    //         </Row>
    //       )}
    //       {isLocalPro && (
    //         <Row style={{ alignItems: 'left' }}>
    //           <span
    //             style={{
    //               marginRight: '5px',
    //             }}
    //           >
    //             {TLT(`含税价`)}
    //           </span>
    //           {goods.symbol + formatMoney(goods.taxSalePrice, false)}
    //         </Row>
    //       )}
    //       {isLocalPro && (
    //         <Row style={{ alignItems: 'left' }}>
    //           <span
    //             style={{
    //               marginRight: '5px',
    //             }}
    //           >
    //             {TLT(`未税价`)}
    //           </span>
    //           {goods.symbol + formatMoney(goods.skuPrice, false)}
    //         </Row>
    //       )}
    //       {isLocalPro && (
    //         <Row style={{ alignItems: 'left' }}>
    //           <span
    //             style={{
    //               marginRight: '5px',
    //             }}
    //           >
    //             {TLT(`税率`)}
    //           </span>
    //           {goods.valueAddedTaxRate + '%'}
    //         </Row>
    //       )}
    //       {isLocalPro && (
    //         <Row>
    //           <Icon icon={iconX} style={{ marginLeft: '2px' }} />
    //           {goods.num}
    //         </Row>
    //       )}
    //     </Col>
    //     {isLocalPro && (
    //       <Col className={[styles.title9, styles.goodsCell].join(' ')} style={{ marginTop: '0px' }}>
    //         {goods?.customsClearance == 1 ? TLT(`是`) : goods?.customsClearance == 0 ? TLT(`否`) : '-'}
    //       </Col>
    //     )}
    //   </Row>
    //   <div className={styles.gift}>
    //     <ServiceStuff data={goods.gift}></ServiceStuff>
    //   </div>
    // </div>
  );
};

export default Goods;
